import styled from 'styled-components'
import { alvTokens } from '@dasa-health/alma-tokens'

export const ChipAns = styled.span`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.6rem;
  background: rgba(22, 22, 22, 0.08);
  border-radius: 24px;
  padding: .4rem .8rem;
  color: ${alvTokens.ColorNeutralInverse20};
  font-weight: 700;
`

export const ContentChipAns = styled.div`
  margin-right: 5px;
`