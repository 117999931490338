import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'
import { Pagination as P } from 'react-instantsearch-dom'
import media from 'styled-media-query'
import styled from 'styled-components'
import { ContainerDasa } from '../shared/container'
import { Heading2 } from '../shared/text-styled'
import { ChipAns } from '../shared/ansChip'
import { ChevronLeft, ChevronRight } from '@dasa-health/alma-icons'
import { alvTokens } from '@dasa-health/alma-tokens'

export const ExamsContainer = styled.div`
  background: ${props => props.theme.colors.brand.primary[10]};
  padding-bottom: 13.6rem;

  ${media.lessThan('medium')`
    padding-bottom: .8rem;
  `}
`

export const ExamsContentHolder = styled.section`
  max-width: 1092px;
  margin: 3rem auto 0;
  display: flex;
  gap: 3.7rem; 
  padding: 4rem 0 0;
  
  ${media.lessThan('medium')`
    flex-direction: column;
    padding: 3.2rem 1.6rem 2.4rem;
    gap: 2.4rem;
  `}
`

export const ExamsSidebarHolder = styled.aside`
  min-width: 31.1rem;
  fill: #e8e8e8 20%;

  ${media.lessThan('medium')`
    display: ${props => props.mobile ? 'block' : 'none'};
  `}
`

export const BtnApplyFilters = styled.button`
  background-color: var(--pink);
  color: var(--white);
  padding: 1.2rem 1.6rem;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  border-radius: 4px;
  border: 0;
  margin: 1.2rem 0 1.4rem;
  display: none;

  ${media.lessThan('medium')`
    display: block;
  `}
`

export const ExamsContentList = styled.div``

export const TextLoading = styled.p`
  font-size: 20px;
  text-align: center;
  color: #7D7D7D;
`

export const TextError = styled.p`
  font-size: 20px;
  text-align: center;
  color: #7D7D7D;
`

export const ExamsContent = styled.section`
  background: ${alvTokens.ColorPaletteWhite};
  padding: 2.4rem;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  display: ${props => props.filtersMobile ? 'none' : 'block'};
  min-width: 74.4rem;

  ${media.lessThan('medium')`
    min-width: auto;
  `}

  & > .ais-Hits > .ais-Hits-list > * {
    margin-bottom: 5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .ais-Hits {
    .ais-Hits-list {
      margin: 0;
      padding: 0;
      .ais-Hits-item {
        list-style: none;
      }
    }
  }
`

export const Title = styled(Heading2)`
  margin-top: 0;
  margin-bottom: 0;

  ${media.lessThan('medium')`
    letter-spacing: -1px;
  `}
`

export const SubTitle = styled.p`
  font-size: 16px;
  color: #7D7D7D;
  margin: .6rem 0 2.3rem;

  .read-more-button {
    color: var(--pink);
    display: block;
    margin-top: 0.7rem;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`

export const ContentTable = styled.table`
  opacity: 0.5;
  font-size: 16px;
  color: #424242;
  text-decoration: none solid rgb(66, 66, 66);
  line-height: 24px;
  letter-spacing: 1px;
  border-collapse: collapse;
`
export const TitleHolder = styled.div`
  margin-bottom: .8rem;
`

export const IconHolder = styled.span``
export const IconLink = styled(Link)`
  color: var(--pink);
  font-size: 24px;
`

export const TitleLink = styled(Link)`
  font-size: 16px;
  color: ${props => props.theme.colors.brand.primary[10]};
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;

  ${media.lessThan('medium')`
    font-weight: 600;
  `}
`
export const Exam = styled.div`
  padding: 2.4rem 1.6rem;
  background: ${alvTokens.ColorPaletteGray160};
  margin-bottom: 2.4rem;
  border-radius: 4px;

  ${media.lessThan('medium')`
    margin-bottom: 1.6rem;
  `}
  
  &:last-child {
      margin-bottom: 0;
  }
`
export const TitleIcon = styled.div`
  text-align: right;
`
export const ContentRow = styled.p`
  display: flex;
  font-size: 14px;
  color: ${props => props.theme.colors.typeface.smooth};
  font-weight: 400;
  line-height: 170%;
  margin: 0;

  & > * {
    &:last-child {
      display: inline-flex;
      margin-left: 0.5rem;
    }
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const AnsDiv = styled.div`
  display: flex;
  width: auto;
  margin-top: .8rem;

  div{
    align-self: center;
    margin-left: 5px;
  }
`

export const AnsChip = styled(ChipAns)`
  width: auto;
  color: ${props => props.theme.colors.typeface.smooth};
  font-size: 12px;
`

export const ContentColumn = styled.span``

export const Pagination = styled(P)`
  font-size: 20px;

  .ais-Pagination-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .ais-Pagination-item {
    display: inline-block;
    margin-right: 1rem;
  }
`

export const PaginationList = styled.ul`
  padding: 4rem 0 0 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3.2rem;

  ${media.lessThan('medium')`
    padding: 2.4rem 0 0 0;
    margin: 0;
  `}
`
export const PaginationListItem = styled.li`
  display: inline-block;
  font-size: 12px;
  color: ${alvTokens.ColorPaletteWhite};
  letter-spacing: 1px;
`

export const PaginationListItemLink = styled.a`
  width: 3.8rem;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  color: ${alvTokens.ColorPaletteWhite};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`

export const PaginationIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  margin-left: 1rem;
  margin-right: 1rem;
`

export const ExamsTitleContainer = styled(ContainerDasa)`
  margin-top: 2.4rem;

  ${media.lessThan('medium')`
    margin-top: 0;
  `}
`

export const FiltersHolder = styled.section`
  max-width: 1092px;
  margin: 0 auto;
  
  ${media.lessThan('medium')`
    padding: 0 1.6rem;
  `}
`

export const FiltersMobile = styled.div`
  display: none;
  width: fit-content;
  min-height: 1.6rem;
  border: 1px solid ${alvTokens.ColorPaletteWhite};
  padding: .3rem 1.2rem;
  border-radius: 24px;
  background: ${props => props.open ? "#ffff" : ''};

  svg {
    color: ${props => props.open ? '#D22673' : alvTokens.ColorPaletteWhite};
    font-size: 1.3rem;
  }

  ${media.lessThan('medium')`
    display: flex;
    align-items: center;
    gap: .4rem;
  `}
`

export const FilterChip = styled.span`
  font-size: 12px;
  color: ${props => props.open ? '#D22673' : alvTokens.ColorPaletteWhite};
  font-weight: 500;
  line-height: 133%;
`

export const RefinementsHolder = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline;
`

export const RefinementsItem = styled.li`
  display: inline;
  line-height: 5rem;

  & > ul > li {
    display: inline-block;
  }
`

export const RefinementName = styled.span`
  border-radius: 4px;
  background: var(--pink);
  padding: 1rem 1.5rem;
  color: var(--white);
  text-decoration: none;
  margin-right: 2rem;
  font-size: 14px;
  cursor: pointer;
`
export const RefinementsItemIcon = styled.i`
  margin-left: 1rem;
  display: inline-block;
  font-size: 14px;
`

export const ClearRefinement = styled.button`
  border: 0;
  background: transparent;
  padding: 1rem 1.5rem;
  color: var(--pink);
  text-decoration: none;
  margin-right: 2rem;
  font-size: 14px;
  cursor: pointer;
`

export const AnsCover = styled.div`
  ${media.lessThan('medium')`
    top: 2px;
  `}
`

export const PaginationIconLeft = styled(ChevronLeft)`
  font-size: 1.85rem;
  color: ${alvTokens.ColorPaletteWhite};
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`

export const PaginationIconRight = styled(ChevronRight)`
  font-size: 1.85rem;
  color: ${alvTokens.ColorPaletteWhite};
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`

export const PaginationItem = styled.span`
  font-size: 14px;
  color: ${alvTokens.ColorPaletteWhite};
  padding: .8rem 1.3rem;
  font-weight: 500;
  line-height: 115%;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`

export const PaginationItemActive = styled(PaginationItem)`
  background-color: var(--pink);
  color: var(--white);
  border-radius: 4px;
`
