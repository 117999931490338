import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@dasa-health/alma-react'
import { Question } from '@dasa-health/alma-icons'


const textDefaultIsAns = 'Este exame faz parte do Rol da ANS, garantindo cobertura obrigatória pelos planos de saúde regulamentados.';

const TooltipCustom = ({ title, text, icon, iconHeight, iconWidth }) => {

    return (
        <Tooltip
            message={text ? text : textDefaultIsAns}
            title={title}
            offset={10}
            placement='bottom'
            zIndex={9999}
            sx={{
                minWidth: 380, '@media (max-width: 440px)': {
                    minWidth: 'auto'
                }
            }}
        >
            {icon ? icon : <Question height={iconHeight ? iconHeight : 15} width={iconWidth ? iconWidth : 15} />}
        </Tooltip>
    )

}

TooltipCustom.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.node,
    iconHeight: PropTypes.number,
    iconWidth: PropTypes.number,
}

export default TooltipCustom;