import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { capitalize, removeLastChar } from '@cms/utils'

import { SampleTypeItem, Info, SpecializationArea } from '../text-styled'
import * as S from '../../single-exam/styled'
import variableSubstitution from '../../../utils/variableSubstitution'
import TooltipCustom from '../TooltipCustom'
import { CheckCircle } from '@styled-icons/boxicons-regular'
import { CloseRound } from '@dasa-health/alma-icons'
import { AnsChip, AnsDiv } from '../../exams/styled'

const examProps = {
  doencas: PropTypes.arrayOf(),
  genes: PropTypes.array,
  preparos: PropTypes.arrayOf({
    item: PropTypes.shape({
      descricao: PropTypes.string,
    }),
  }),
  areaEspecializacaoProduto: PropTypes.shape({
    areaEspecializacao: PropTypes.arrayOf(
      PropTypes.shape({
        nome: PropTypes.string,
      })
    ),
  }),
}

const Preparation = ({ exam }) => {
  return exam.preparation.map((item, idx) => {
    if (item.descricao === 'Não se aplica') {
      return null
    }
    return <li key={idx}>{item.descricao}</li>
  })
}

Preparation.props = {
  exam: examProps,
}

const AnsNotCover = ({ data }) => {
  return (
    <S.InfoContainer>
      <S.ContentAns>
        <S.ExamSpecTitle>{data.title_ans_coverage_section}</S.ExamSpecTitle>
      </S.ContentAns>
      <AnsDiv>
        <AnsChip>
          <CloseRound height={15} width={15} style={{ marginRight: 10 }} />
          Exame sem cobertura ANS
        </AnsChip>
        <TooltipCustom text="Exame fora do Rol da ANS. Consulte seu plano para verificar a disponibilidade de cobertura." />
      </AnsDiv>
    </S.InfoContainer>
  )
}

const AnsCover = ({ data }) => {
  return (
    <S.InfoContainer>
      <S.ContentAns>
        <S.ExamSpecTitle>{data.title_ans_coverage_section}</S.ExamSpecTitle>
      </S.ContentAns>
      <AnsDiv>
        <AnsChip>
          <CheckCircle height={15} width={15} style={{ marginRight: 10 }} />
          Este exame possui cobertura ANS
        </AnsChip>
        <TooltipCustom />
      </AnsDiv>
    </S.InfoContainer>
  )
}

const AnalyzedGenes = ({ exam, data }) => {
  const [genesList, setGenesList] = useState([])

  const geneCount = exam.genes.length

  const sliceGenesInitialList = () => (geneCount > 20 ? exam.genes.slice(0, 20) : exam.genes)

  const canViewMore = () => geneCount > 20

  const countRemainingGenes = geneCount - 20

  const eachGenesName = variableSubstitution(data.text_counter_genes, 'XX', countRemainingGenes)

  useEffect(() => {
    const genes = sliceGenesInitialList()
    setGenesList(genes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <S.AllGenesContainer>
      {genesList.map((item, index) => {
        return String(item).startsWith('+') ? (
          ''
        ) : (
          <S.EachGenesContainer key={index}>
            <S.EachGenesName>{removeLastChar(item)}</S.EachGenesName>
          </S.EachGenesContainer>
        )
      })}
      {canViewMore() && (
        <S.EachGenesContainer key={Math.random()}>
          <S.EachGenesName>{eachGenesName}</S.EachGenesName>
        </S.EachGenesContainer>
      )}
    </S.AllGenesContainer>
  )
}

AnalyzedGenes.propTypes = {
  exam: examProps,
}

const AnalyzedGenesForDownload = ({ exam }) => {
  const [genesList, setGenesList] = useState([])

  const geneCount = exam.genes.length

  const sliceGenesInitialList = () => (geneCount > 20 ? exam.genes.slice(0, 20) : exam.genes)

  useEffect(() => {
    const genes = sliceGenesInitialList()
    setGenesList(genes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <S.AllGenesContainer>
      {genesList.map((item, index) => {
        return String(item).startsWith('+') ? (
          <S.EachGenesContainer key={index}>
            <S.EachGenesName>{removeLastChar(item)}</S.EachGenesName>
          </S.EachGenesContainer>
        ) : (
          ''
        )
      })}
    </S.AllGenesContainer>
  )
}

AnalyzedGenesForDownload.propTypes = {
  exam: examProps,
}

const SpecializationProduct = ({ exam, noResults }) => {
  const specializationProduct = exam.specializationProduct.areaEspecializacao
  const formattedSpecializationAreas = specializationProduct
    .map(item => capitalize(item.nome))
    .join(', ')
  return <SpecializationArea>{formattedSpecializationAreas || noResults}</SpecializationArea>
}

const Diseases = ({ exam, noResults }) => {
  return exam.diseases ? (
    <S.InfoList>
      {exam.diseases.map((item, index) => {
        return <S.InfoListItem key={index}>{item}</S.InfoListItem>
      })}
    </S.InfoList>
  ) : (
    <Info>{noResults}</Info>
  )
}

Diseases.propTypes = {
  exam: examProps,
}

const Materials = ({ exam, noResults }) => {
  return exam.materials ? (
    <S.SampleType>
      {exam.materials.sort().map((item, index) => {
        return <SampleTypeItem key={index}>{item.nome}</SampleTypeItem>
      })}
    </S.SampleType>
  ) : (
    <Info>{noResults}</Info>
  )
}

Materials.propTypes = {
  exam: examProps,
}

export {
  Preparation,
  AnsCover,
  AnsNotCover,
  AnalyzedGenes,
  AnalyzedGenesForDownload,
  SpecializationProduct,
  Diseases,
  Materials,
}
